<esos-page-heading size="xl"
  >Environment Agency - Energy Savings Opportunity Scheme (ESOS) Privacy Notice
</esos-page-heading>

<p class="govuk-body">
  This notice sets out how we will use your personal data, and your rights. It is made under Articles 13 and/or 14 of
  the UK General Data Protection Regulation (UK GDPR).
</p>

<h2 class="govuk-heading-m">Your Data</h2>
<br />
<h3 class="govuk-heading-s">The data</h3>
<p class="govuk-body">We will process the following personal data:</p>
<ul class="govuk-list govuk-list--bullet">
  <li>First and last name (middle name optional)</li>
  <li>Email address (business or personal)</li>
  <li>Job title or position</li>
  <li>Contact information (business or personal)</li>
</ul>

<p class="govuk-body">We collect these details about:</p>
<ul class="govuk-list govuk-list--bullet">
  <li>The ESOS responsible officer contacts for the participant. These include the:</li>
  <ul class="govuk-list govuk-list--bullet">
    <li>Primary Officer</li>
    <li>Secondary contact</li>
    <li>Board Directors and / or individuals with management control for ESOS</li>
  </ul>
  <li>The full name and the approved professional body register for the lead assessor where relevant.</li>
</ul>
<p class="govuk-body">
  We process your personal data under the Energy Savings Opportunities Scheme Regulations 2014. We are allowed to
  process your personal data because we have official authority as the ESOS Administrator. The lawful basis for
  processing your personal data is to perform a task in the public interest that is set out in law.
</p>
<p class="govuk-body">
  We need all the information requested from you to administer and regulate your organisation’s participation in ESOS.
</p>

<h3 class="govuk-heading-s">Purpose</h3>
<p class="govuk-body">
  The purpose(s) for which we are processing your personal data is to regulate and administer your participation in ESOS
  and the ESOS Notification System. This includes:
</p>
<p class="govuk-body">
  Processing your Energy Savings Opportunities Scheme (ESOS) account registration. Publish your ESOS compliance
  notifications, ESOS action plans and annual progress reports.
</p>

<h3 class="govuk-heading-s">Legal basis of processing</h3>
<p class="govuk-body">The legal basis for processing your personal data is:</p>
<p class="govuk-body">
  <strong> Legal obligation:</strong> It is necessary to comply with a legal obligation placed on us as the data
  controller. Details are outlined in the Energy Savings Opportunities Scheme Regulations 2014.
</p>

<h3 class="govuk-heading-s">Recipients</h3>
<p class="govuk-body">
  The Environment Agency is the scheme administrator of the ESOS reporting service and your personal data will be shared
  by us with the data controllers outlined in this privacy notice as well as with Trasys UniSystems, the Information
  Technology (IT) supplier which provides data processing and storage service for the administration of the ESOS
  reporting service and Fordway, the supplier which provides the service desk.
</p>
<p class="govuk-body">
  Your personal data will be stored on our IT infrastructure, and it will also be shared with our data processors
  Microsoft and Amazon Web Services.
</p>
<p class="govuk-body">
  Under Regulation 9 of the Energy Savings Opportunity Scheme Regulations 2014 (“the 2014 Regulations”) the Environment
  Agency is required to provide to the Secretary of State for Energy Security and Net Zero (DESNZ) any information held
  by it by virtue of the 2014 Regulations as is requested. DESNZ’s Personal Information Charter explains individuals’
  rights over how their information is used and includes Privacy Notices used in specific circumstances:
</p>
<p>
  <a
    class="govuk-link"
    href="https://www.gov.uk/government/organisations/department-for-energy-security-and-net-zero/about/personal-information-charter"
    target="_blank"
    rel="noreferrer noopener"
  >
    https://www.gov.uk/government/organisations/department-for-energy-security-and-net-zero/about/personal-information-charter
  </a>
</p>

<h3 class="govuk-heading-s">Retention</h3>
<p class="govuk-body">
  Your personal data is held during your organisation’s participation in each compliance period of the ESOS scheme. A
  compliance period runs for 4 years. We will delete your data held for a compliance period once 2 subsequent compliance
  periods have elapsed.
</p>

<h3 class="govuk-heading-s">Automated decision making</h3>
<p class="govuk-body">Your personal data will not be subject to automated decision making.</p>

<h3 class="govuk-heading-s">Your rights</h3>
<p class="govuk-body">
  You have the right to request information about how your personal data are processed, and to request a copy of that
  personal data.
</p>
<p class="govuk-body">
  You have the right to request that any inaccuracies in your personal data are rectified without delay.
</p>
<p class="govuk-body">
  You have the right to request that any incomplete personal data are completed, including by means of a supplementary
  statement.
</p>
<p class="govuk-body">
  You have the right to request that your personal data are erased if there is no longer a justification for them to be
  processed.
</p>
<p class="govuk-body">
  You have the right in certain circumstances (for example, where accuracy is contested) to request that the processing
  of your personal data is restricted.
</p>
<p class="govuk-body">
  You have the right to object to the processing of your personal data where it is processed for direct marketing
  purposes.
</p>

<h3 class="govuk-heading-s">International transfers</h3>
<p class="govuk-body">
  Your personal data will not be processed outside the UK and European Economic Area (EEA), or by an international
  organisation.
</p>
<p class="govuk-body">
  As your personal data is stored on our IT infrastructure and shared with our data processors Microsoft and Amazon Web
  Services, it may be transferred and stored securely outside the UK and EEA. Where that is the case it will be subject
  to equivalent legal protection through the use of Model Contract Clauses.
</p>

<h3 class="govuk-heading-s">Complaints</h3>
<p class="govuk-body">
  If you consider that your personal data has been misused or mishandled, you may make a complaint to the Information
  Commissioner, who is an independent regulator. The Information Commissioner can be contacted at:
</p>
<ul class="govuk-list govuk-list--bullet">
  <li>Information Commissioner's Office</li>
  <li>Wycliffe House</li>
  <li>Water Lane</li>
  <li>Wilmslow</li>
  <li>Cheshire</li>
  <li>SK9 5AF</li>
  <li>0303 123 1113</li>
  <li>Email: <a class="govuk-link" href="mailto:casework@ico.org.uk"> casework@ico.org.uk </a></li>
</ul>
<p class="govuk-body">
  Any complaint to the Information Commissioner is without prejudice to your right to seek redress through the courts.
</p>

<h3 class="govuk-heading-s">Contact details</h3>
<p class="govuk-body">
  The data controller for your personal data is dependent on the nation in the United Kingdom in which the Responsible
  Undertaking is registered.
</p>
<p class="govuk-body">
  <strong>
    The Energy Savings Opportunities Scheme reporting service has 5 data controllers. The contact details for each of
    these Data Controllers are below:
  </strong>
</p>
<ul class="govuk-list govuk-list--bullet">
  <li>The Department for Energy Security and Net Zero</li>
  <li>The Environment Agency (EA)</li>
  <li>The Scottish Environment Protection Agency (SEPA)</li>
  <li>Natural Resources Wales (NRW)</li>
  <li>The Department of Agriculture, Environment and Rural Affairs – Northern Ireland (DAERA)</li>
</ul>

<h3 class="govuk-heading-s">The Department for Energy Security and Net Zero</h3>
<p class="govuk-body">You can contact the Department for Energy Security and Net Zero Data Protection Officer at:</p>
<ul class="govuk-list govuk-list--bullet">
  <li>The Department for Energy Security and Net Zero Data Protection Officer</li>
  <li>The Department for Energy Security and Net Zero</li>
  <li>1 Victoria Street</li>
  <li>London</li>
  <li>SW1H 0ET</li>
  <li>
    Email:
    <a class="govuk-link" href="mailto:dataprotection@energysecurity.gov.uk"> dataprotection@energysecurity.gov.uk </a>
  </li>
</ul>

<h3 class="govuk-heading-s">Environment Agency</h3>
<p class="govuk-body">You can contact the Environment Agency Data Protection Officer at:</p>
<ul class="govuk-list govuk-list--bullet">
  <li>Data Protection Team</li>
  <li>Environment Agency</li>
  <li>Horizon House</li>
  <li>Deanery Road</li>
  <li>Bristol BS1 5AH</li>
  <li>
    Email:
    <a class="govuk-link" href="mailto:dataprotection@energysecurity.gov.uk"> dataprotection@energysecurity.gov.uk </a>
  </li>
</ul>

<h3 class="govuk-heading-s">Scottish Environment Protection Agency</h3>
<p class="govuk-body">You can contact the Scottish Environment Protection Agency Data Protection Officer at:</p>
<ul class="govuk-list govuk-list--bullet">
  <li>Alison M. Mackinnon</li>
  <li>Data Protection Officer</li>
  <li>Scottish Environment Protection Agency (SEPA)</li>
  <li>Strathallan House</li>
  <li>Castle Business Park</li>
  <li>Stirling</li>
  <li>FK9 4TZ</li>
  <li>Email: <a class="govuk-link" href="mailto:dataprotection@sepa.org.uk"> dataprotection@sepa.org.uk </a></li>
</ul>

<h3 class="govuk-heading-s">Natural Resource Wales</h3>
<p class="govuk-body">You can contact the Natural Resource Wales Data Protection Officer at:</p>
<p class="govuk-body">
  <a class="govuk-link" href="mailto:dataprotection@cyfoethnaturiolcymru.gov.uk">
    dataprotection@cyfoethnaturiolcymru.gov.uk
  </a>
</p>
<p class="govuk-body">
  Their Privacy Notice can be found here:
  <a
    class="govuk-link"
    href="https://naturalresources.wales/footer-links/privacy-notice/?lang=en#:~:text=NRW%20may%20disclose%20personal%20data,law%2C%20and%20by%20court%20order."
    target="_blank"
    rel="noreferrer noopener"
  >
    Natural Resources Wales / Privacy notice
  </a>
</p>

<h3 class="govuk-heading-s">Department of Agriculture, Environment and Rural Affairs</h3>
<p class="govuk-body">
  You can contact the Department of Agriculture, Environment and Rural Affair Data Protection Officer at:
</p>
<ul class="govuk-list govuk-list--bullet">
  <li>Department of Agriculture, Environment and Rural Affairs</li>
  <li>Jubilee House</li>
  <li>111 Ballykelly Road</li>
  <li>Ballykelly</li>
  <li>Limavady</li>
  <li>BT49 9HP</li>
  <li>DAERA Data Protection Officer (DPO)</li>
  <li>
    Email:
    <a class="govuk-link" href="mailto:dataprotectionofficer@daera-ni.gov.uk">
      dataprotectionofficer@daera-ni.gov.uk
    </a>
  </li>
</ul>
<div class="govuk-!-margin-bottom-9"></div>
<esos-back-to-top></esos-back-to-top>
