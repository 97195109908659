<ng-template #checkboxTemplate>
  <div class="govuk-checkboxes__item">
    <input
      (blur)="onBlur()"
      (change)="onChange($event)"
      [attr.aria-controls]="conditional ? identifier + 'conditional' : null"
      [attr.aria-describedby]="identifier + '-item-hint'"
      [attr.value]="value"
      [checked]="isChecked"
      [disabled]="isDisabled"
      [id]="identifier"
      [name]="groupIdentifier"
      class="govuk-checkboxes__input"
      type="checkbox"
    />
    <label [for]="identifier" class="govuk-label govuk-checkboxes__label">
      <ng-container *ngIf="label; else customLabel">{{ label }}</ng-container>
      <ng-template #customLabel>
        <ng-content select="[govukLabel]"></ng-content>
      </ng-template>
    </label>
    <div *ngIf="hint" [id]="identifier + '-item-hint'" class="govuk-hint govuk-checkboxes__hint">{{ hint }}</div>
  </div>
</ng-template>
<ng-template #conditionalTemplate>
  <div
    *ngIf="conditional"
    [class.govuk-checkboxes__conditional--hidden]="!isChecked"
    [id]="identifier + 'conditional'"
    class="govuk-checkboxes__conditional"
  >
    <ng-content select="[govukConditionalContent]"></ng-content>
  </div>
</ng-template>
