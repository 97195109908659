<esos-page-heading size="xl">Contact the ESOS reporting helpdesk</esos-page-heading>

<p class="govuk-body">If you need help accessing or using the ESOS reporting service, please contact your regulator.</p>

<ul class="govuk-list govuk-list--bullet" style="list-style-type: none">
  <li>
    Environment Agency (EA):
    <a class="govuk-link" href="mailto:esos-it@environment-agency.gov.uk"> esos-it@environment-agency.gov.uk </a>
  </li>
</ul>
