<esos-page-heading size="xl">
  Accessibility statement for Department of Energy Security and Net Zero (DESNZ)
</esos-page-heading>

<p class="govuk-body">
  This accessibility statement applies to the Energy Savings Opportunity Scheme (ESOS) Digital Service.
</p>

<p class="govuk-body">
  This website is run by DESNZ We want as many people as possible to be able to use this website. For example, that
  means you should be able to:
</p>

<ul class="govuk-list govuk-list--bullet">
  <li>change colours, contrast levels and fonts</li>
  <li>zoom in up to 300% without the text spilling off the screen</li>
  <li>navigate most of the website using just a keyboard</li>
  <li>navigate most of the website using speech recognition software</li>
  <li>
    listen to most of the website using a screen reader (including the most recent versions of JAWS, NVDA and VoiceOver)
  </li>
</ul>

<p class="govuk-body">We’ve also made the website text as simple as possible to understand.</p>

<p class="govuk-body">
  <a class="govuk-link" href="https://mcmw.abilitynet.org.uk/" rel="noreferrer noopener">AbilityNet</a> has advice on
  making your device easier to use if you have a disability.
</p>

<h2 class="govuk-heading-l">How accessible this website is</h2>
<p class="govuk-body">We know some parts of this website are not fully accessible:</p>

<ul class="govuk-list govuk-list--bullet">
  <li>Form input labels are inappropriate</li>
  <li>Multiple fieldset legends are blank</li>
  <li>Related links are not grouped within a nav element</li>
  <li>Targets lack sufficient spacing</li>
  <li>The skip to main content does not take the user to main content</li>
  <li>Page titles are not descriptive</li>
  <li>Purposes of links cannot be determined</li>
  <li>Multiple instances of identical links text</li>
  <li>Required format field information is not provided</li>
  <li>The tab order is illogical on mobile</li>
  <li>Broken ARIA reference present</li>
  <li>Headings and labels do not describe topic or purpose</li>
  <li>Search results are not announced</li>
  <li>Invalid announcements on IOS Voiceover and Android Talkback</li>
  <li>'Skip to Main Content' does not function as expected with assistive technologies</li>
  <li>Incorrect usage of autocomplete attribute</li>
  <li>Screen reader announcements are incorrect</li>
  <li>Screen readers are unable to access select tabs within ‘Account overview’</li>
  <li>Screen readers do not announce percentage symbols</li>
  <li>Screen readers do not announce when they are navigated to a new page</li>
</ul>

<h3 class="govuk-heading-m">Feedback and contact information</h3>
<p class="govuk-body">
  If you need information on this website in a different format like accessible PDF, large print, easy read, audio
  recording or braille please contact us at:
  <a class="govuk-link" href="mailto:businessenergyuse@energysecurity.gov.uk">
    businessenergyuse@energysecurity.gov.uk
  </a>
</p>

<h3 class="govuk-heading-m">Reporting accessibility problems with this website</h3>
<p class="govuk-body">
  We’re always looking to improve the accessibility of this website. If you find any problems not listed on this page or
  think we’re not meeting accessibility requirements, contact:
  <a class="govuk-link" href="mailto:businessenergyuse@energysecurity.gov.uk">
    businessenergyuse@energysecurity.gov.uk
  </a>
</p>

<h3 class="govuk-heading-m">Enforcement procedure</h3>
<p class="govuk-body">
  The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and
  Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’). If you’re not happy
  with how we respond to your complaint,
  <a href="https://www.equalityadvisoryservice.com/" class="govuk-link" rel="noreferrer noopener">
    contact the Equality Advisory and Support Service (EASS) </a
  >.
</p>

<h2 class="govuk-heading-l">Technical information about this website’s accessibility</h2>
<p class="govuk-body">
  DESNZ is committed to making its website accessible, in accordance with the Public Sector Bodies (Websites and Mobile
  Applications) (No. 2) Accessibility Regulations 2018.
</p>

<h3 class="govuk-heading-m">Compliance status</h3>
<p class="govuk-body">
  This website is partially compliant with the
  <a class="govuk-link" rel="noreferrer noopener" href="https://www.w3.org/TR/WCAG22/">
    Web Content Accessibility Guidelines version 2.2
  </a>
  AA standard, due to the non-compliances and exemptions listed below.
</p>

<h2 class="govuk-heading-l">Non-accessible content</h2>
<p class="govuk-body">The content listed below is non-accessible for the following reasons.</p>

<h3 class="govuk-heading-m">Non-compliance with the accessibility regulations</h3>
<p class="govuk-body">
  Form input fields lack an appropriate text label or descriptive titles. Across many pages, many of the form input
  element labels are inappropriate. This can affect users of screen readers. This fails WCAG 2.2 success criterion 1.1.1
  - Non-text Content.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  Multiple fieldset elements are observed with a blank legend element. Meaning contextual information is not announced
  by screen readers. This fails WCAG 2.2 success criterion 1.3.1 - Info and Relationships.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  Multiple targets do not have sufficient spacing. A target size minimum of 24x24 is not met. This fails WCAG 2.2
  success criterion 2.5.8 Target Size (minimum).
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  The ‘Skip to main content’ function does not take users to main page content. This fails WCAG 2.2 success criterion
  2.4.1 - Bypass Blocks.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  Many of the pages share the same page title or are not descriptive, meaning the user may find it difficult to
  differentiate between different pages using the browser tab alone. This fails WCAG 2.2 success criterion 2.4.2 - Page
  Titled.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  Links are present across several account pages which are not distinguishable enough to provide context when navigating
  the page. This fails WCAG 2.2 success criterion 2.4.4 - Link Purpose (In Context).
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  Multiple instances of identical link text are present which direct the user to different locations. This fails WCAG
  2.2 success criterion 2.4.4 - Link Purpose (In Context).
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  Required or specific format fields do not provide information in the associated label or title attribute to indicate
  the required/specific format. This fails WCAG 2.2 success criterion 3.2.2 - Labels or Instructions.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  The tab order is not logical on any pages where the mobile menu is present. The user is first focused to the ‘Sign
  Out’ link, before navigating to the mobile menu, which does not match the visual layout of the page. This fails WCAG
  2.2 success criterion 2.4.3 - Focus Order.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  Broken ARIA references are present on all pages. An aria-labelledby or aria-describedby reference exists, but the
  target for the reference does not exist. This fails WCAG 2.2 success criterion 1.3.1 Info and Relationships
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  On many pages, the headings or labels do not describe the topic or purpose. This fails WCAG 2.2 success criterion
  2.4.6 - Headings and Labels.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  The ‘Search’ field on the ‘Accounts’ page presents results which are not announced to the user. This fails WCAG 2.2
  success criterion 4.1.3 - Status Messages.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  When navigating the site using screen reader technologies, Android Talkback and iOS Voiceover multiple announcement
  issues are present. This fails WCAG 2.2 success criterion 4.1.2 - Name, Role, Value.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  When navigating page content with screen readers such as NVDA and Android TalkBack, the ‘Skip to main content’ CTA
  does not skip past common navigational elements. This fails WCAG 2.2 success criterion 2.4.1 - Bypass Blocks.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  Incorrect usage of the autocomplete attribute is present for form input fields. Input fields are identified with
  inappropriate 'autocomplete' attributes. This fails WCAG 2.2 success criterion 1.3.5 - Identify Input Purpose.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  When navigating the site using NVDA, several elements are announced as “blank”. This fails WCAG 2.2 success criterion
  4.1.2 - Name, Role, Value.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  When navigating the site using NVDA decorative separators are unexpectedly announced. This fails WCAG 2.2 success
  criterion 4.1.2 - Name, Role, Value.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  When navigating the site using NVDA, on the ‘Accounts Overview’ page, the user is unable to access the content within
  the ‘Organisation Details,’ ‘Phases,’ and ‘Workflow History’ tabs. This fails WCAG 2.2 success criterion 4.1.2 - Name,
  Role, Value.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  Across screen readers, percentage symbols are not announced. This fails WCAG 2.2 success criterion 4.1.2 - Name, Role,
  Value.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<p class="govuk-body">
  Across all screen readers page titles are not announced after navigation. User may be unaware that a page has loaded
  or that they have been navigated to the expected page. This fails WCAG 2.2 success criterion 4.1.2 - Name, Role,
  Value.
</p>

<p class="govuk-body">We plan to resolve this by: 1st June 2024</p>

<h2 class="govuk-heading-l">Preparation of this accessibility statement</h2>
<p class="govuk-body">This statement was prepared on 6th March. It was last reviewed on 11th March.</p>
<p class="govuk-body">This website was last tested on 22nd February. The test was carried out by Zoonou.</p>
<p class="govuk-body">
  Zoonou used <a class="govuk-link" rel="noreferrer noopener" href="https://www.w3.org/TR/WCAG-EM"> WCAG-EM </a> to
  define a representative sample of pages to test.
</p>

<div class="govuk-!-margin-bottom-9"></div>
<esos-back-to-top></esos-back-to-top>
